
// Variables
@import "variables";



.product-avatar{
  max-width: 100%;
}

.navbar{
  margin-bottom: 0px;
}

.big-padding{
  padding: 30px;
}

.blue-grey{
  background-color:#37474f;
}
.whit-text{
  color:white;
}
.table{
  background-color:white;
}
